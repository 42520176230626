import { Outlet, useNavigate } from "react-router-dom";
import Header from "./ui/Header";
import { useEffect, useState } from "react";
import Container from "./ui/Container";
import Sidebar from "./ui/Sidebar";
import Panel from "./ui/panel/Panel";
import Pages from "../utils/Pages";



const RootLayout = () => {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);

  const handleSidebar = () => setSidebar(!sidebar);

  const profile =
    JSON.parse(localStorage.getItem("remoteProfile")) ||
    JSON.parse(localStorage.getItem("profile"));

  const filterPagesByPermissions = (pages, permissions) => {
    return pages
      .map((category) => {
        const filteredChildren = category.children.filter((child) => {
          return permissions[child.permissionName];
        });

        return {
          ...category,
          children: filteredChildren,
        };
      })
      .filter((category) => category.children.length > 0);
  };

  const filteredOperationalPages = filterPagesByPermissions(
    Pages.operationalPages,
    profile.viewPermissions.operationalPages
  );

  const filteredDefinitionPages = filterPagesByPermissions(
    Pages.definitionPages,
    profile.viewPermissions.definitionPages
  );

  const filteredFinancialPages = filterPagesByPermissions(
    Pages.financialPages,
    profile.viewPermissions.financialPages
  );

  const filteredPages = {
    operationalPages: filteredOperationalPages,
    definitionPages: filteredDefinitionPages,
    financialPages: filteredFinancialPages,
  };

  // Pages.operationalPages = filteredPages.operationalPages;
  // Pages.definitionPages = filteredPages.definitionPages;
  // Pages.financialPages = filteredPages.financialPages;

  const links = [
    ...filteredPages.operationalPages,
    ...filteredPages.definitionPages,
    ...filteredPages.financialPages,
  ];

  useEffect(
    function () {
      if (!profile.viewPermissions || !profile.functionalPermissions) {
        localStorage.clear();
        navigate("/login");
      }
    },
    [profile, navigate]
  );

  return (
    <div className="App">
      <aside className="hidden lg:block ">
        <Panel links={links} />
      </aside>
      <Sidebar show={sidebar} handleSidebar={handleSidebar} links={links} />
      <main>
        <Header handleSidebar={handleSidebar} className={"mb-12"} />
        <Container>
          {/* A placeholder for pages */}
          <Outlet />
        </Container>
      </main>
    </div>
  );
};

export default RootLayout;
